<template>
  <div id="user_cabinet_header" class="user-cabinet__inner">
    <div class="user-cabinet__area">
      <Alert :variant="variant" v-if="text">{{ text }}</Alert>
      <template v-if="projects.length">
        <div class="user-cabinet__area-top">
          <router-link class="btn btn-primary" :to="{ name: 'strategyProjectCreate' }">
            New Strategy Project
          </router-link>
        </div>
        <div v-if="projects.length" class="project__list">
          <ContainerCardStrategyProject
            v-for="project in projects"
            :key="project.id + project.name"
            :project="project"
            :isGroup="false"
            :unreadCommentsCount="unreadProjects.find((pr) => pr.id === project.id)"
          />
          <!--          <Pagination v-if="pagination.pageCount > 1"-->
          <!--            :pagination="pagination"-->
          <!--            v-on:changepage="handlePagination"-->
          <!--          />-->
        </div>
      </template>
      <p v-if="projectsLoaded" v-html="content.block[0]"></p>
    </div>
    <Modal
      @close="hideModal('deleteModal')"
      id="deleteModal"
      title="Are you sure?"
      :buttonTextOk="modal.btnCaption"
      :buttonVariantOk="modal.variant"
      :showButtonCancel="true"
      @cancel="hideModal('deleteModal')"
      @ok="onDeleteProject"
    />
  </div>
</template>
<script>
import ContainerCardStrategyProject
from '@/views/profile/Components/ContainerCardStrategyProject.vue';
// import Pagination from '@/views/profile/MyGroups/Pagination.vue';
import Modal from '@/components/Modal.vue';
import Alert from '@/components/Alert.vue';

// import pagination from '@/mixins/pagination';
import exception from '@/mixins/exception';
import modal from '@/mixins/modal';
import alert from '@/mixins/alert';
import scroll from '@/mixins/scroll';
import content from '@/mixins/content';
import websocket from '@/mixins/websocket';
import me from '@/mixins/me';

import ProjectService from '@/services/ProjectService';

export default {
  name: 'ProfileStrategyProjects',
  components: {
    ContainerCardStrategyProject,
    // Pagination,
    Modal,
    Alert,
  },
  mixins: [exception, modal, alert, scroll, content, websocket, me],
  data() {
    return {
      projects: [],
      projectsLoaded: false,
      projectIdDelete: undefined,
      projectParams: {
        page: 1,
        perPage: 5,
      },
      totalPages: 1,
      modal: {
        id: 'deleteModal',
        variant: 'danger',
        btnCaption: 'Delete',
        userID: 0,
        type: '',
        action: '',
      },
    };
  },
  computed: {
    unreadProjects() {
      return this.$store.getters.getUnreadProjects;
    },
  },
  async created() {
    await this.getProjects();

    this.$eventBus.$on('deleteProject', this.onShowModal);
    this.$eventBus.$on('addLike', this.onAddLike);
    this.$eventBus.$on('removeLike', this.onRemoveLike);
  },
  mounted() {
    window.addEventListener('scroll', async () => {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;
      const footer = document.getElementsByClassName('footer');
      if (window.innerWidth <= 768
        && scrollTop + clientHeight >= scrollHeight - footer[0].scrollHeight) {
        await this.getProjects();
      }
      if (window.innerWidth > 768 && scrollTop + clientHeight >= scrollHeight) {
        await this.getProjects();
      }
    });
  },
  methods: {
    async getProjects() {
      let res = null;
      if (this.projectParams.page <= this.totalPages) {
        try {
          res = await ProjectService.projectsMy({
            page: this.projectParams.page,
            'per-page': this.projectParams.perPage,
          });
          this.projects.push(...res.data);
          this.totalPages = res.headers['x-pagination-page-count'];
          this.projectParams.page = +res.headers['x-pagination-current-page'] + 1;
          this.projectsLoaded = true;
        } catch (e) {
          // this.projects = [];
          // this.showAndHideAlertSuccess(res.response.data.message, 'danger');
          this.handleException(e);
        }
      }
    },
    onShowModal(id) {
      this.projectIdDelete = id;
      this.showModal(this.modal.id);
    },
    // TODO: прокрутка наверх при видаленні
    onDeleteProject() {
      this.totalPages = 1;
      this.projectParams.page = 1;
      this.deleteProject()
        .then((async () => {
          let res = null;
          if (this.projectParams.page <= this.totalPages) {
            try {
              res = await ProjectService.projectsMy({
                page: this.projectParams.page,
                'per-page': this.projectParams.perPage,
              });
              this.projects = res.data;
              this.totalPages = res.headers['x-pagination-page-count'];
              this.projectParams.page = +res.headers['x-pagination-current-page'] + 1;
              this.projectsLoaded = true;
            } catch (e) {
              this.projects = [];
              this.showAndHideAlertSuccess(res.response.data.message, 'danger');
              this.handleException(e);
            }
          }
          // await this.getProjects();
          this.scrollToTop('user_cabinet_header');
          this.hideModal(this.modal.id);
        }));
    },
    async deleteProject() {
      console.log('del');
      let res = null;

      try {
        res = await ProjectService.delete(this.projectIdDelete);
        this.showAndHideAlertSuccess(res.data.message, 'success');
      } catch (e) {
        this.hideModal(this.modal.id);
        this.scrollToTop('user_cabinet_header');
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
        this.handleException(e);
      }
    },
    async onAddLike(projectID) {
      try {
        await ProjectService.addLike(projectID);
        const projectUpdate = this.projects.find((project) => project.id === projectID);

        projectUpdate.like = true;
        projectUpdate.cnt_like += 1;
      } catch (e) {
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
        this.handleException(e);
      }
    },
    async onRemoveLike(projectID) {
      try {
        await ProjectService.removeLike(projectID);
        const projectUpdate = this.projects.find((project) => project.id === projectID);

        projectUpdate.like = false;
        projectUpdate.cnt_like -= 1;
      } catch (e) {
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
        this.handleException(e);
      }
    },
  },
  beforeDestroy() {
    this.$eventBus.$off('deleteProject', this.onShowModal);
    this.$eventBus.$off('addLike', this.onAddLike);
    this.$eventBus.$off('removeLike', this.onRemoveLike);
    window.removeEventListener('scroll', async () => {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;
      const footer = document.getElementsByClassName('footer');
      if (window.innerWidth <= 768
        && scrollTop + clientHeight >= scrollHeight - footer[0].scrollHeight) {
        await this.getProjects();
      }
      if (window.innerWidth > 768 && scrollTop + clientHeight >= scrollHeight) {
        await this.getProjects();
      }
    });
  },
};
</script>
